@import url("https://fonts.googleapis.com/css?family=Bungee+Hairline|Comfortaa:700|Libre+Baskerville:700");

* {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  box-sizing: border-box;
}

html {
  -webkit-animation: color-change-3x 4s linear infinite alternate both;
  -moz-animation: color-change-3x 4s linear infinite alternate both;
  -o-animation: color-change-3x 4s linear infinite alternate both;
  animation: color-change-3x 4s linear infinite alternate both;
  font-size: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Comfortaa", cursive;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
}

#super {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  padding: 5%;
  width: 100%;
  height: 100%;
}

/* Background Color Transition */
@-webkit-keyframes color-change-3x {
  0% {
    background: #8fbc8f;
  }

  50% {
    background: #74a7c6;
  }

  100% {
    background: #dbd7d2;
  }
}

@-moz-keyframes color-change-3x {
  0% {
    background: #8fbc8f;
  }

  50% {
    background: #74a7c6;
  }

  100% {
    background: #dbd7d2;
  }
}

@-o-keyframes color-change-3x {
  0% {
    background: #8fbc8f;
  }

  50% {
    background: #74a7c6;
  }

  100% {
    background: #dbd7d2;
  }
}

@keyframes color-change-3x {
  0% {
    background: #8fbc8f;
  }

  50% {
    background: #74a7c6;
  }

  100% {
    background: #dbd7d2;
  }
}