@import url(https://fonts.googleapis.com/css?family=Bungee+Hairline|Comfortaa:700|Libre+Baskerville:700);
.text-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.full_name,
.title,
.email {
  flex-basis: 100%;
  text-align: center;
}

.full_name {
  font-family: "Libre Baskerville", serif;
  font-size: 4em;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
}

.title {
  font-size: 3.5em;
  letter-spacing: 0.05em;
  -webkit-margin-before: 0.3em;
          margin-block-start: 0.3em;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.email {
  font-size: 2.5em;
  letter-spacing: 0.15em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-before: 0.5em;
          margin-block-start: 0.5em;
}

.email>a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.link_buttons {
  display: flex;
  flex-basis: 75%;
  flex-direction: row;
  justify-content: space-around;
}

.link_button:focus {
  outline: 0;
}

.link_button {
  background: none;
  border: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 2em;
  margin: 1em 0.7em;
  color: rgb(255, 255, 255);
}
.rbc-outer {
  -webkit-transform-style: flat;
          transform-style: flat;
}

.rbc-wrapper {
  position: relative;
  transition: -webkit-transform 100ms ease-out 0s;
  transition: transform 100ms ease-out 0s;
  transition: transform 100ms ease-out 0s, -webkit-transform 100ms ease-out 0s;
}

.rbc-shadow {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
  bottom: 0;
  right: 0;
  background: none;
  border-radius: 2.75rem;
  transition: box-shadow 100ms ease-out 0s;
}

.rbc-layers {
  width: 100%;
  height: 100%;
}

.rbc-layer-00 {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: -webkit-transform 100ms ease-out 0s;
  transition: transform 100ms ease-out 0s;
  transition: transform 100ms ease-out 0s, -webkit-transform 100ms ease-out 0s;
}

.rbc-layer-01 {
  width: 100%;
  height: 100%;
  position: absolute;
  color: #ffffff;
  z-index: 1;
  transition: -webkit-transform 100ms ease-out 0s;
  transition: transform 100ms ease-out 0s;
  transition: transform 100ms ease-out 0s, -webkit-transform 100ms ease-out 0s;
}

.rbc-lighting {
  border-radius: 2.75rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: -webkit-transform 100ms ease-out 0s;
  transition: transform 100ms ease-out 0s;
  transition: transform 100ms ease-out 0s, -webkit-transform 100ms ease-out 0s;
}
#my-card {
  padding: 0;
  margin: 0;
  -webkit-animation: bounce-in-bottom 1.1s both;
  animation: bounce-in-bottom 1.1s both;
  -webkit-animation-delay: 8.6s;
  animation-delay: 8.6s;
}

img {
  border-radius: 2.75rem;
}

/*  Card Entrance  */
@-webkit-keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateY(65px);
    transform: translateY(65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateY(28px);
    transform: translateY(28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateY(65px);
    transform: translateY(65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateY(28px);
    transform: translateY(28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
#console-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  font-size: calc(18px + (120 - 18) * ((100vw - 300px) / (2400 - 300)));
  animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 8s both;
  -webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 8s both;
  -moz-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 8s both;
  -o-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 8s both;
}

.console {
  margin: 20vh 5vw 20vh 5vw;
  width: 90%;
  height: 60%;
}

.line {
  text-align: left;
}

#line-1 {
  margin-top: 10vh;
  padding-bottom: calc((40vh - 3em)/2);
}

#line-2 {
  margin-top: 0;
  padding-bottom: calc((40vh - 3em)/2);
}

#line-3 {
  margin-bottom: 10vh;
}

/* Emojis */
#alien::before {
  content: '👾'
}

#seedling::before {
  content: '🌱'
}

#heart::before {
  content: '❤️'
}

/* Exit Animation */
@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}
* {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  box-sizing: border-box;
}

html {
  -webkit-animation: color-change-3x 4s linear infinite alternate both;
  animation: color-change-3x 4s linear infinite alternate both;
  font-size: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Comfortaa", cursive;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
}

#super {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  padding: 5%;
  width: 100%;
  height: 100%;
}

/* Background Color Transition */
@-webkit-keyframes color-change-3x {
  0% {
    background: #8fbc8f;
  }

  50% {
    background: #74a7c6;
  }

  100% {
    background: #dbd7d2;
  }
}

@keyframes color-change-3x {
  0% {
    background: #8fbc8f;
  }

  50% {
    background: #74a7c6;
  }

  100% {
    background: #dbd7d2;
  }
}
